import React from 'react';
import injectSheet from 'react-jss';
import Modal from 'react-modal';
import Helmet from 'react-helmet';

import Translate from '../../components/Translate';
import Layout from '../../components/layout';
import themeColors from '../../styles/theme';
import colors from '../../styles/colors';
import Button from '../../components/button';
import InfoSection from '../../components/infoSection';
import i18next from '../../config/i18next';
import { isChinaUser } from '../../config/isChina';

import OneImage from '../../data/images/features/pregnancy/desktop/one.jpg';
import OneImageMobile from '../../data/images/features/pregnancy/mobile/one_m.jpg';
import TwoImage from '../../data/images/features/pregnancy/desktop/two.jpg';
import TwoImageMobile from '../../data/images/features/pregnancy/mobile/two_m.jpg';
import ThreeImage from '../../data/images/features/pregnancy/desktop/three.jpg';
import ThreeImageMobile from '../../data/images/features/pregnancy/mobile/three_m.jpg';
import FourImage from '../../data/images/features/pregnancy/desktop/four.jpg';
import FourImageMobile from '../../data/images/features/pregnancy/mobile/four_m.jpg';
import FiveImage from '../../data/images/features/pregnancy/desktop/last.jpg';
import FiveImageMobile from '../../data/images/features/pregnancy/mobile/last_m.jpg';

const styles = {
    landingPage: {
        background: themeColors.white,
        '& a': {
            color: themeColors.white,
            textDecorationLine: 'underline'
        },
        '& button a': {
            color: themeColors.primaryButtonLinkText
        },
        '& .compatibleLink': {
            color: 'unset'
        }
    },
    modal: {
        width: '800px',
        margin: '7% 0 0 15%',
        background: '#fff',
        '& iframe': {
            margin: '0 0 10px 20px'
        }
    },
    modalHeader: {
        padding: '7px 15px',
        color: '#222',
        overflow: 'hidden',
        lineHeight: '1',
        display: 'flex'
    },
    modalCloseButton: {
        color: '#888',
        cursor: 'pointer',
        border: '0',
        opacity: '1',
        padding: '0',
        fontSize: '15px',
        background: 'transparent',
        fontWeight: '400',
        lineHeight: '11px',
        margin: '7px 0 0 auto'
    },
    blogButton: {
        height: '44px',
        padding: '0 25px',
        fontSize: '16px',
        margin: 0,
        cursor: 'pointer',
        borderRadius: '4px',
        border: 'none',
        display: 'block',
        transition: 'background-color 200ms',
        backgroundColor: themeColors.primaryButtonBackground,
        color: (props) => themeColors.primaryButtonText,
        '&:focus': {
            backgroundColor: themeColors.primaryButtonBackgroundFocus,
            outline: 'none',
            boxShadow: '0 0 0 2px ' + colors.white + ', 0 0 0 4px ' + themeColors.primary
        },
        '&:hover': {
            backgroundColor: themeColors.primaryButtonBackgroundHover
        },
        '&:active': {
            backgroundColor: themeColors.primaryButtonBackgroundActive
        },
        '&[disabled]': {
            boxShadow: 'none',
            opacity: '0.5',
            cursor: 'not-allowed',
            pointerEvents: 'none'
        }
    },
    disclaimerSection: {
        width: '100%',
        padding: '50px 0px 50px',
        backgroundColor: colors.lightui_surface_4,
        textAlign: 'center',
        fontSize: '10px'
    },
    deviceList: {
        textDecorationLine: 'none !important'
    },
    paddingTop: {
        paddingTop: '20px'
    },
    '@media screen and (max-width: 767px)': {
        landingPage: {
            '& .contractionLink': {
                color: '#333333'
            }
        },
        breakPoint: {
            marginTop: '20px'
        }
    }
};

Modal.setAppElement(`#___gatsby`);
class PregnancyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            isMobile: false,
            title: '',
            updateCompatibleDeviceLink: false
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.setState({ updateCompatibleDeviceLink: isChinaUser() });
        this.loadTitleTranslation();
        this.resize();
        window.addEventListener('resize', this.resize.bind(this));
    }

    /*
     * The purpose of this function is to ensure that tranlations are loaded before we set the title in the head. React Helmet does not allow React
     * nodes for the title so we cannot use Translate component. Using i18next directly would just load the key as i18next hasn't loaded the the json files yet.
     * So we need to keep track of the title state and update it once i18next loads translations
     */
    loadTitleTranslation() {
        i18next.loadNamespaces('main_container', (err) => {
            if (err) {
                console.log(err[0]);
            }

            let text = i18next.t(`prelogin_pages:pregnancy_tracking_title`, { skipInterpolation: true });
            this.setState({ title: text });
        });
    }

    resize() {
        if (typeof window !== `undefined`) {
            let renderMobile = window.innerWidth <= 767;
            if (renderMobile !== this.state.isMobile) {
                this.setState({ isMobile: renderMobile });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    getMobileVideoLink() {
        if (isChinaUser()) {
            return this.getChinaVideoLink();
        } else {
            return this.getYoutubeLink();
        }
    }

    getYoutubeLink() {
        switch (i18next.language) {
            case 'zh-TW':
                return 'https://www.youtube.com/watch?v=O7DsLr2HK-8&feature=youtu.be';
            case 'in-ID':
                return 'https://www.youtube.com/watch?v=hWGFdn8u_3k&feature=youtu.be';
            case 'ko-KR':
                return 'https://www.youtube.com/watch?v=OXAjN91blxA&feature=youtu.be';
            case 'ja-JP':
                return 'https://www.youtube.com/watch?v=MSL3jqJBg14&feature=youtu.be';
            case 'th-TH':
                return 'https://www.youtube.com/watch?v=yDfbtG3JWys&feature=youtu.be';
            case 'vi-VN':
                return 'https://www.youtube.com/watch?v=sJxTN681HIo&feature=youtu.be';
            default:
                return 'https://youtu.be/N9Q4j4JzXbM';
        }
    }

    getEmbedVideoLink() {
        if (isChinaUser()) {
            return this.getChinaVideoLink();
        } else {
            return this.getEmbedYoutubeLink();
        }
    }

    getEmbedYoutubeLink() {
        switch (i18next.language) {
            case 'zh-TW':
                return 'https://www.youtube.com/embed/O7DsLr2HK-8?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            case 'in-ID':
                return 'https://www.youtube.com/embed/hWGFdn8u_3k?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            case 'ko-KR':
                return 'https://www.youtube.com/embed/OXAjN91blxA?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            case 'ja-JP':
                return 'https://www.youtube.com/embed/MSL3jqJBg14?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            case 'th-TH':
                return 'https://www.youtube.com/embed/yDfbtG3JWys?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            case 'vi-VN':
                return 'https://www.youtube.com/embed/sJxTN681HIo?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            default:
                return 'https://www.youtube.com/embed/N9Q4j4JzXbM?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
        }
    }

    getChinaVideoLink() {
        return 'https://player.youku.com/embed/XNTA4NjkxOTcyOA';
    }

    getPregnancyURL = () => {
        return this.state.updateCompatibleDeviceLink
            ? `https://apps.garmin.cn/${i18next.language}/apps/ec04a948-34b5-4740-83fb-67a63a8912d6`
            : `https://apps.garmin.com/${i18next.language}/apps/ec04a948-34b5-4740-83fb-67a63a8912d6`;
    };

    getContractionTimerURL = () => {
        return this.state.updateCompatibleDeviceLink
            ? `https://apps.garmin.cn/${i18next.language}/apps/766f112a-0e34-4863-bbcf-eeccccb9c55c`
            : `https://apps.garmin.com/${i18next.language}/apps/766f112a-0e34-4863-bbcf-eeccccb9c55c`;
    };

    getCompatibleWatchesURL = () => {
        return this.state.updateCompatibleDeviceLink
            ? `https://apps.garmin.cn/${i18next.language}/apps/ec04a948-34b5-4740-83fb-67a63a8912d6?tab=compatible-devices`
            : `https://apps.garmin.com/${i18next.language}/apps/ec04a948-34b5-4740-83fb-67a63a8912d6?tab=compatible-devices`;
    };

    getCompatibleSmartphoneLink = () => {
        return 'https://support.garmin.com/sas/connect/?contentId=pvL8aWsaLU2iKyvF8VrpP9';
    };

    render() {
        const { classes } = this.props;
        const button = this.state.isMobile ? (
            <Button size="large">
                <a href={this.getMobileVideoLink()}>{<Translate ns="prelogin_pages" content="watch_the_video" />}</a>
            </Button>
        ) : (
            <Button size="large" onClick={this.openModal}>
                {<Translate ns="prelogin_pages" content="watch_the_video" />}
            </Button>
        );
        const title = this.state.title;

        return (
            <Layout>
                <div className={classes.landingPage}>
                    <Helmet>
                        <title>Garmin Connect | {title}</title>
                        <meta name="title" content="Pregnancy Tracking" />
                        <meta property="og:title" content="Pregnancy Tracking" />
                        <meta property="og:url" content={`https://connect.garmin.com/features/pregnancy-tracking`} />
                        <meta
                            property="og:image"
                            content="http://static.garmincdn.com/com.garmin.connect/content/images/garmin-connect-fb3.gif"
                        />
                        <meta name="twitter:title" content="Garmin Pregnancy Tracking" />
                    </Helmet>
                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="pregnancy_tracking_title" />}
                        contentList={[
                            <Translate
                                ns="prelogin_pages"
                                tag="span"
                                content="pregnancy_tracking_info"
                                params={{
                                    0: this.getCompatibleSmartphoneLink()
                                }}
                            />
                        ]}
                        desktopImage={OneImage}
                        mobileImage={OneImageMobile}
                        allowFullScreenImage={true}
                        textAlign="right"
                        textColor="#ffffff"
                    >
                        <div>
                            <br />
                            {button}
                            <Modal
                                isOpen={this.state.modalIsOpen}
                                onRequestClose={this.closeModal}
                                contentLabel="Garmin Menstrual Cycle Tracking"
                                className={classes.modal}
                            >
                                <div className={classes.modalHeader}>
                                    <button className={classes.modalCloseButton} onClick={this.closeModal}>
                                        x
                                    </button>
                                </div>
                                <iframe
                                    title="Youtube id 1aZbyw3HoH4"
                                    src={this.getEmbedVideoLink()}
                                    frameBorder="0"
                                    allowFullScreen
                                    width="760"
                                    height="428"
                                />
                            </Modal>
                        </div>
                    </InfoSection>
                    <div className={classes.breakPoint}>
                        <InfoSection
                            contentTitle={<Translate ns="prelogin_pages" content="understanding_body_title" />}
                            contentList={[<Translate ns="prelogin_pages" content="understanding_body_info" />]}
                            desktopImage={TwoImage}
                            mobileImage={TwoImageMobile}
                            allowFullScreenImage={true}
                            textAlign="right"
                            textColor="#ffffff"
                        />
                    </div>

                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="exercise_and_nutrition_title" />}
                        contentList={[<Translate ns="prelogin_pages" content="exercise_and_nutrition_info" />]}
                        desktopImage={ThreeImage}
                        mobileImage={ThreeImageMobile}
                        allowFullScreenImage={true}
                        textColor="#ffffff"
                    />

                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="log_daily_symptoms_title" />}
                        contentList={[<Translate ns="prelogin_pages" content="log_daily_symptoms_info" />]}
                        desktopImage={FourImage}
                        mobileImage={FourImageMobile}
                        allowFullScreenImage={true}
                    />

                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="track_contractions_title" />}
                        contentList={[
                            <Translate
                                ns="prelogin_pages"
                                tag="span"
                                content="track_contractions_info"
                                params={{
                                    0: this.getPregnancyURL(),
                                    1: this.getContractionTimerURL()
                                }}
                            />,
                            <div className={classes.paddingTop}>
                                <Button size="large">
                                    <a className={classes.deviceList} href={this.getCompatibleWatchesURL()}>
                                        {<Translate ns="prelogin_pages" content="mct_compatible_devices_label" />}
                                    </a>
                                </Button>
                            </div>
                        ]}
                        desktopImage={FiveImage}
                        mobileImage={FiveImageMobile}
                        allowFullScreenImage={true}
                        textAlign="right"
                        textColor="#ffffff"
                    />

                    <div className={classes.disclaimerSection}>
                        <Translate
                            ns="prelogin_pages"
                            tag="div"
                            content="disclaimer_text"
                            params={{
                                0: this.getCompatibleSmartphoneLink()
                            }}
                        />
                        <Translate
                            ns="prelogin_pages"
                            tag="div"
                            content="compatible_disclaimer_text"
                            params={{
                                0: this.getCompatibleWatchesURL()
                            }}
                        />
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(PregnancyPage);
